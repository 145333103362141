import localFont from 'next/font/local';
import Head from 'next/head';

export const veloSerifDisplay = localFont({
    src: [
        {
            path: '../../fonts/VeloSerifDisplay/VeloSerifDisplay-Bold.woff2',
            weight: '700',
            style: 'normal',
        },
    ],
    display: 'swap',
});

export const VeloSerifDisplay = () => (
    <Head>
        <style
            key="fontfaces-velo-serif-display"
            className={veloSerifDisplay.className}
            dangerouslySetInnerHTML={{
                __html: `
/*This CSS resource incorporates links to web
font and font software which is the valuable
copyrighted property of House Industries and/
or its suppliers. You expressly agree to not copy,
install, redistribute, convert, modify, or reverse
engineer this font software or the fonts rendered
thereby. Please contact House Industries with
any questions regarding Web Fonts: http://www.
houseind.com*/

:root {
    --font-family-velo-serif-display: 'Velo Serif Display', ${veloSerifDisplay.style.fontFamily}, Helvetica, Arial, sans-serif;
}`,
            }}
        />
    </Head>
);
